* {
  box-sizing: border-box;
}
.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  color: white;
}

.icon-home {
  color: #727e94;
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 10px;
}

nav a {
  color: #727e94;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
}

nav .nav-wrapper {
  display: flex;
}

nav .nav-wrapper a {
  font-size: 40px;
}

nav .nav-wrapper .nav-item {
  margin: 0 30px;
}

.dice-reset {
  cursor: pointer;
  margin-bottom: 10px;
}

.dice-container {
  display: flex;
  width: 302px;
  flex-wrap: wrap;
  border: #727e94 solid 1px;
  border-radius: 2%;
}

.dice-two-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  border: #727e94 solid 1px;
  border-radius: 2%;
}

.dice-two-container .active svg {
  color: rgb(97, 148, 231);
}

.dice-two-container-inner-left {
  display: flex;
  flex-wrap: wrap;
  width: 210px;
  margin: 0 10px;
  justify-content: center;
  align-items: flex-start;
}

.dice-two-container-inner-right {
  display: flex;
  flex-wrap: wrap;
  width: 100px;
  padding-top:10px;
  justify-content: center;
  align-items: flex-start;
}

.dice-two-list-item {
  width: 120px;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 15px;
}

.dice-two {
  width: 80px;
  margin: 10px;
}

.dice-two svg {
  cursor: pointer;
}

.dice-two-list {
  width: 80px;
  margin: 10px 0;
}

.dice-two-list-item span {
  font-size: 14px;
  justify-content: center;
}
.dice-two-list-item span::selection {
  background: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
